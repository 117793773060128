body {
  background: #282c34;
  font-family: '나눔스퀘어', nanum-square, sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}
.root {
  background: #fff;
  border-radius: 1.5rem;
  max-width: 100vw;
  min-width: 800px;
  padding: 1.5rem;
  width: 50vw;
}
a {
  color: #679;
}
a:hover {
  text-decoration: underline;
}
h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-block-end: 1.5rem;
}
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

header {
  margin-bottom: 1.5rem;
}
header a {
  color: black;
  font-family: '배달의민족 도현', '배달의민족도현', bm-dohyeon, sans-serif;
  font-size: 2.5rem;
}

main,
main > section[role='tabpanel']:not([hidden]) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
main > section[role='tabpanel'] {
  width: 100%;
}
main h1 {
  width: 100%;
}
.left,
.right {
  width: 50%;
}
.left {
  padding-right: 0.5rem;
}
.right {
  padding-left: 0.5rem;
}
footer {
  font-size: 0.8rem;
  margin-block-end: 2rem;
  text-align: right;
}

form dl {
  display: grid;
  grid-template-columns: max-content 1fr;
}
form dl :is(dt, dd) {
  padding-block: 0.5rem;
}
form dl :is(dt, dd):nth-of-type(2n + 1) {
  background: #f9f9f9;
}
form dl dt {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-inline-end: 1rem;
}
form dl dt label {
  margin-left: 2.5rem;
}
form dl dd {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
form dl dd label {
  width: 85%;
}
@supports (user-select: none) {
  form dl dd label input[type='number'] + span {
    user-select: none;
  }
}
form dl dd p {
  color: #777;
  font-size: 0.9rem;
  margin-block-start: 0.5rem;
  width: 100%;
}
form dl dd p + p {
  margin-block-start: 0.25rem;
}
label[for] {
  cursor: pointer;
}
select {
  appearance: auto;
  border-radius: 0.1rem;
  border: 1px solid #ccc;
  line-height: 1.5;
  padding: 0.5rem;
}
select + :is(input, button) {
  margin-left: 0.5rem;
}
input {
  border-radius: 0.1rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: right;
}
input:invalid {
  border-color: #f77;
}
[role='alert'][aria-live='assertive'] {
  color: #f77;
}
input[type='number'] {
  width: 50%;
}
input[type='checkbox'] {
  appearance: auto;
  margin-inline-end: 0.5rem;
}
input + :is(span, select) {
  margin-inline-start: 0.5rem;
}
ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-block-start: 1rem;
  margin-inline-start: 1rem;
}
ul li:not(:last-of-type) {
  margin-block-end: 0.5rem;
}
main.index a svg {
  margin-inline-start: 0.25rem;
}

section {
  margin-bottom: 2.5rem;
}

svg {
  margin-right: 0.5rem;
}
table {
  margin-inline-start: 1rem;
  width: calc(90% - 1rem);
}
:is(th, td) {
  border: 1px solid #ccc;
  padding: 0.5rem;
}
th {
  font-weight: bold;
}
thead th {
  font-size: 1.2rem;
  text-align: center;
}
tbody th {
  text-align: center;
  vertical-align: middle !important;
  width: 40%;
}
tbody td {
  text-align: right;
  width: 60%;
}

table.estimation tbody th {
  text-align: center;
  vertical-align: middle !important;
  width: 25%;
}
table.estimation tbody td {
  text-align: left;
  width: 75%;
}

ol.sponsors {
  display: flex;
  list-style-type: none;
  margin-block-start: 1rem;
  margin-inline-start: 1rem;
}

ol.sponsors li {
  font-size: 0.9rem;
}
ol.sponsors li:not(:last-of-type)::after {
  content: ',';
  margin-inline-end: 0.25rem;
}

button {
  background: #fff;
  border: 0.1rem solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
}
button:is(:hover, :focus-visible) {
  border-color: #777;
}
button.cancel {
  background: #f77;
  border-color: #f77;
  color: #fff;
}
button.cancel:is(:hover, :focus-visible) {
  background: #d44;
  border-color: #d44;
  color: #fff;
}
button[type='submit'] {
  background: #68b;
  border-color: #68b;
  color: #fff;
}
button[type='submit']:is(:hover, :focus-visible) {
  background: #469;
  border-color: #469;
  color: #fff;
}
.buttons {
  margin-block-start: 1rem;
  text-align: right;
}
.buttons button:not(:last-of-type) {
  margin-inline-end: 0.5rem;
}
dialog {
  background: #fff;
  border: 0.1rem solid #444;
  border-radius: 0.5rem;
  left: 50%;
  /* eslint-disable-next-line css/no-invalid-properties */
  max-width: min(100vw, 500px);
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
[role='tablist'] {
  display: flex;
  gap: 0.3rem;
  list-style: none;
  margin: 0;
}
button[role='tab'] {
  border-bottom: unset;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  cursor: pointer;
}
button[role='tab'][aria-selected='true'] {
  background: #444;
  border-color: #444;
  color: #fff;
}

@media (max-width: 700px) {
  #__next {
    align-items: flex-start;
  }
  .root {
    border-radius: 0;
    max-width: unset;
    min-width: unset;
    padding: 1rem;
    width: 100vw;
  }
  .left,
  .right {
    width: 100%;
  }
  dt,
  dd {
    font-size: 0.9rem;
  }
  input,
  select {
    font-size: 0.8rem;
  }
  input + span {
    margin-inline-start: 0.25rem;
  }
  input + select {
    margin-inline-start: 0.1rem;
  }
}
